import React, { useContext, useEffect, useState } from 'react'
import { Button, enhancedFetch } from 'journey-ui'
import { useRouter } from 'next/router'
import { AppContext } from '../contexts/AppContext'

export const TabletBackupLogin = () => {
  const { setUser } = useContext(AppContext)
  const [device, setDevice] = useState<Device | undefined>()

  /* This is a backup system encase our tablets ever logout, we can get them back into tablet mode. */
  useEffect(() => {
    const deviceUid = localStorage.getItem('deviceUid')

    if (!deviceUid) {
      return
    }

    enhancedFetch<Device>(`/api/device/${deviceUid}`)
      .then(setDevice)
      .catch(console.error)
  }, [])

  const onClearClick = () => {
    localStorage.removeItem('deviceUid')
    setDevice(undefined)
  }

  const router = useRouter()

  const onLoginClick = async () => {
    const { user: tabletUser } = await enhancedFetch<{ user:User }>(`/api/device/${localStorage.getItem('deviceUid')}/login-from`, {
      method: 'POST',
    })

    setUser(tabletUser)
    setDevice(undefined)

    router.push('/').catch(console.error)
  }

  if (!device?.is_tablet) {
    return null
  }

  return (
    <>
      {device ? (
        <div className="w-full flex justify-between items-center bg-green-600 p-3 text-white text-center">
          <div className="flex items-center space-x-4">
            <span>
              We detected a previous login from "
              {device.brand_name}
              ".
            </span>

            <Button onClick={onLoginClick}>
              Enter
            </Button>
          </div>

          <Button className="danger" onClick={onClearClick}>
            Clear
          </Button>
        </div>
      ) : null}
    </>
  )
}
