import React, { useContext, useEffect, useState } from 'react'
import { Button, createUrl, enhancedFetch, JourneyAppContext, Spinner, TextInput } from 'journey-ui'
import { useRouter } from 'next/router'
import { AppContext } from '../../contexts/AppContext'
import { PizzaPrinter } from '../../pizza-printer'

export const LoginRequestPanel = () => {
  const [restaurantName, setRestaurantName] = useState('')
  const [cityName, setCityName] = useState('')

  const [processing, setProcessing] = useState(false)
  const [ipAddress, setIpAddress] = useState('')
  const [masonUuid, setMasonUuid] = useState('')

  const router = useRouter()
  const { setError } = useContext(JourneyAppContext)

  const { setUser, user } = useContext(AppContext)

  useEffect(() => {
    if (!ipAddress || !processing || restaurantName === '') {
      return
    }

    const sendLoginRequestPing = () => enhancedFetch(createUrl('/api/login-request', {
      device_name: navigator.userAgent,
      ip_address: ipAddress,
      mason_uuid: masonUuid,
      restaurant_name: restaurantName,
      city_name: cityName,
      latitude: 0,
      longitude: 0,
    })).then((resp: { user:User }) => {
      if (resp.user) {
        setUser(resp.user)

        setTimeout(() => {
          router.push('/').catch(console.error)
        }, 0)
      }
    })

    const t = setInterval(() => {
      sendLoginRequestPing().catch(console.error)
    }, 30_000)

    return () => clearInterval(t)
  }, [router, setUser, user, ipAddress, processing, restaurantName, masonUuid, cityName])

  const onRequestLoginClick = async () => {
    if (restaurantName === '') {
      setError(new Error('Please enter a restaurant name.'))
      return
    }

    try {
      const { uuid } = await PizzaPrinter.getByMasonUuid()

      console.log(`ByMason UUID: ${uuid}`)

      setMasonUuid(uuid)
    } catch {
      // do nothin
    }

    try {
      const resp = await fetch('https://api.ipify.org/?format=json')
      const data = await resp.json()
      setIpAddress(data?.ip)
    } catch {
      // do nothin
    }

    setProcessing(true)
  }

  return (
    <div className="flex flex-col h-full relative items-center p-3 space-y-3 justify-center">
      {processing ? (
        <>
          <Spinner />
          <div>
            Your request has been sent, please give us 5 minutes to review.
            This page will automatically redirect upon approval.
            After 5 minutes if nothing happens please give us a call!
          </div>
        </>
      )
        : (
          <>
            <div>
              Enter your restaurant name and
              {' '}
              <b>city name</b>
              {' '}
              so we know who is requesting access.
            </div>
            <TextInput
              value={restaurantName}
              label=""
              placeholder="Restaurant Name"
              onChange={(e) => setRestaurantName(e.target.value)}
            />

            <TextInput
              value={cityName}
              label=""
              placeholder="City Name"
              onChange={(e) => setCityName(e.target.value)}
            />
            <Button className="w-full justify-center" onClick={() => onRequestLoginClick()}>
              Request Login
            </Button>
          </>
        )}
    </div>
  )
}
