import React, { useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  A,
  Button,
  ContentBg,
  enhancedFetch,
  Form,
  Icon,
  Img,
  isServer,
  PasswordInput,
  SubmitButton,
  TextInput,
} from 'journey-ui'
import { CaretLeft } from 'phosphor-react'
import { AppPage } from '../components/Layout/AppPage'
import { isTablet } from '../helpers'
import { AppContext } from '../contexts/AppContext'
import { TabletBackupLogin } from '../components/TabletBackupLogin'
import { LoginRequestPanel } from '../components/Login/LoginRequestPanel'
import { PizzaPrinter } from '../pizza-printer'

const LoginPage: React.FC = () => {
  const router = useRouter()
  const { setUser, user } = useContext(AppContext)

  // todo: figure out why i have to do this for the android app to work.
  // basically it seems that on initial load of app, the url may be loading before the cookies are loaded
  // thus the login page shows even though they are logged in, may have to do with the cookies being samesite?
  // CookieManager.getInstance().getCookie() only showed cookies who did not have samesite flag set to lax
  // useEffect(() => {
  //   if (user) {
  //     router.push('/')
  //   }
  // }, [router, user])

  const submitForm = async (form) => {
    const user = await enhancedFetch<User>('/api/auth/login', {
      method: 'POST',
      body: form,
    })

    setUser(user)

    setTimeout(() => {
      router.push((router.query?.redirect_to as string) ?? '/').catch(console.error)
    }, 0)
  }

  const [form, setForm] = useState({
    email: '',
    password: '',
  })

  const [loginType, setLoginType] = useState('')
  const [hasBackBtn, setHasBackBtn] = useState(true)

  useEffect(() => {
    // tablets only
    if (!isServer() && !isTablet() && loginType !== 'credentials') {
      setLoginType('credentials')
      setHasBackBtn(false)
    }
  }, [loginType])

  /* If we can detect the IMEI, we can automatically login. */
  useEffect(() => {
    if (!isTablet() || user || loginType !== 'login-request') {
      return
    }

    async function run() {
      const { uuid } = await PizzaPrinter.getByMasonUuid()

      console.log(`ByMason UUID: ${uuid}`)

      if (uuid) {
        const newUser = await enhancedFetch<User>('/api/pos/login-with-by-mason-uuid', {
          method: 'POST',
          body: { uuid },
        })

        if (newUser) {
          setUser(newUser)

          setTimeout(() => {
            router.push('/')
              .catch(console.error)
          }, 0)
        }
      }
    }

    run().catch(console.error)
  }, [router, user, setUser, loginType])

  /* If somehow they got to the login page but have a user, refresh and go to index page to login right. */
  useEffect(() => {
    function run() {
      enhancedFetch('/api/user').then(() => {
        window.location.href = '/'
      }).catch(console.error)
    }

    const t = setInterval(() => {
      run()
    }, 10_000)

    run()

    return () => clearInterval(t)
  }, [])

  return (
    <AppPage title="Login" className="login-page">

      <TabletBackupLogin />

      <div className="login-frame">
        <div className="z-20 flex flex-col my-8">
          <Img size={{ w: 400, h: 75 }} alt="logo" src="/logo.png" className="max-w-[75vw]" />
          <span className="my-2 text-xl text-white">Point Of Sale</span>
        </div>
        <div className="login-panel">
          {loginType !== '' && hasBackBtn ? (
            <div className="w-full flex justify-start px-2 my-2">
              <Button onClick={() => setLoginType('')}>
                <Icon src={CaretLeft} />
              </Button>
            </div>
          ) : null}
          {loginType === '' ? (
            <div className="flex flex-col w-full sm:flex-row space-y-2 space-x-0 sm:space-y-0 sm:space-x-2 h-full relative items-center p-3 justify-center">
              <span className="font-bold text-lg">Select one of the buttons below to continue.</span>
              <button
                type="button"
                className="w-full sm:w-1/2 cursor-pointer px-2 hover:bg-gray-200 text-2xl h-full border-2 border-gray-200 rounded flex justify-center items-center"
                onClick={() => setLoginType('login-request')}
              >
                I don't know my email or password.
              </button>

              <button
                type="button"
                className="w-full sm:w-1/2 cursor-pointer px-2 hover:bg-gray-200 text-2xl h-full border-2 border-gray-200 rounded flex justify-center items-center"
                onClick={() => setLoginType('credentials')}
              >
                I know my email and password.
              </button>
            </div>
          ) : null}
          {loginType === 'credentials' ? (
            <Form value={form} onChange={setForm} className="p-4">
              <TextInput label="Email" name="email" type="email" />
              <PasswordInput label="Password" name="password" />
              <SubmitButton className="w-full h-10 primary my-2 font-bold justify-center" onClick={() => submitForm(form)}>
                Login
              </SubmitButton>

              <div className="flex justify-between w-full">
                <A className="block" href="/forgot-password">
                  Forgot password?
                </A>
                <A className="block" href="/sign-up">
                  Sign Up
                </A>
              </div>
            </Form>
          ) : null}
          {loginType === 'login-request' ? (
            <LoginRequestPanel />
          ) : null}
        </div>

        <ContentBg src="https://pizzamico8-dev.s3.amazonaws.com/branding/pizza-man-cover.png" />
      </div>
    </AppPage>
  )
}

export default LoginPage
